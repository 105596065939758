import { Component, Input, ElementRef, HostBinding } from '@angular/core';

import { DswSoModule } from '../dsw-so.module';

export interface TargetAudience {
  whoFor: string[];
  tags: string[];
}

@Component({
  standalone: true,
  imports: [DswSoModule],
  selector: 'target-audiemce-chips',
  templateUrl: './target-audiemce-chips.component.html',
  styleUrls: ['./target-audiemce-chips.component.scss']
})
export class TargetAudiemceChipsComponent {
  _targetAudience: TargetAudience;
  @Input() set targetAudience(targetAudience: TargetAudience | string) {
    if (targetAudience) {
      if (typeof targetAudience === 'object') {
        this._targetAudience = targetAudience as TargetAudience;
      }
      else {
        this._targetAudience = JSON.parse(targetAudience);
      } 
    }
    console.log("Got Audiance ", targetAudience);
  }
  get targetAudience(): TargetAudience {
    return this._targetAudience
  }
  @Input() expertizeLevel: string;
  @Input() inCard: boolean;
  
//  @HostBinding('style.margin-bottom.px') get marginBotton() {
//    if (!this.inCard) {
//      return 40;
//    }
//  }

  constructor(public element: ElementRef) {
    console.log("In constructor", element.nativeElement.getAttribute("targetaudience"));
    let targetString = element.nativeElement.getAttribute("targetaudience");
    if (targetString) {
      this.targetAudience = JSON.parse(targetString);
    }
    this.expertizeLevel = element.nativeElement.getAttribute("expertizelevel");
  }

}
