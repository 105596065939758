import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

import { DesignerComponent } from './designer/designer.component';
import { SpotlightComponent } from './spotlight/spotlight.component';
import { PeopleComponent } from './people/people.component';
import { FounderComponent } from './founder/founder.component';
import { DeveloperComponent } from './developer/developer.component';
import { GrowthComponent } from './growth/growth.component';
import { MakerComponent } from './maker/maker.component';
import { ProductComponent } from './product/product.component';
import { TrackIconComponent } from './track-icon.component';
import { MartiniComponent } from './martini/martini.component';
import { StarComponent } from './star/star.component';
import { LogoWhiteComponent } from './logo-white/logo-white.component';
import { IntermediateComponent } from './intermediate/intermediate.component';
import { AdvancedComponent } from './advanced/advanced.component';
import { ExpertComponent } from './expert/expert.component';
import { BeginnerComponent } from './beginner/beginner.component';
import { DswPrmaryComponent } from './dsw-prmary/dsw-prmary.component';


@NgModule({
  declarations: [
    DesignerComponent,
    SpotlightComponent,
    PeopleComponent,
    FounderComponent,
    DeveloperComponent,
    GrowthComponent,
    MakerComponent,
    ProductComponent,
    TrackIconComponent,
    MartiniComponent,
    StarComponent,
    LogoWhiteComponent,
    IntermediateComponent,
    AdvancedComponent,
    ExpertComponent,
    BeginnerComponent,
    DswPrmaryComponent
  ],
  imports: [
    CommonModule,
    PortalModule
  ],
  exports: [
    TrackIconComponent,
    LogoWhiteComponent,
    DswPrmaryComponent
  ]
})
export class IconsModule { }
