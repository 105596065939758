<svg
   class="icon"
   viewBox="0 0 120.48751 31.522321"
   version="1.1"
   id="svg1"
   width="120.48751"
   height="31.522322"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs1" />
  <path
     d="m 25,20.761161 h -9 v 2 h 9 z m -18.54,2 6.54,-6.54 v -0.92 L 6.46,8.7611611 5,10.221161 l 5.55,5.54 -5.55,5.54 z M 0,3.7611611 V 27.761161 H 30 V 3.7611611 Z M 28,25.761161 H 2 V 5.7611611 h 26 z"
     id="path1"
     style="fill:#e2ca66;fill-opacity:1" />
  <path
     d="M 37.705628,0.76116135 V 30.76116 h 18 V 0.76116135 Z m 9,28.99999865 a 1,1 0 1 1 1,-1 1,1 0 0 1 -1,1 z m 7,-3 h -14 V 2.7611614 h 14 z"
     id="path1-2"
     style="fill:#9c9cf2;fill-opacity:1" />
  <path
     d="m 76,14.761099 a 1,1 0 1 0 1,1 1,1 0 0 0 -1,-1 z M 75.9,6.7610997 c -5.48,0.06 -9.72,3.5400003 -14.9,9.1599993 4.57,4.71 8.4,8.91 15.1,8.84 6.7,-0.07 11.55,-5.5 14.9,-9.06 C 87.49,11.6011 82.52,6.6910997 75.9,6.7610997 Z m 0.16,15.9999993 c -5.23,0.06 -8.42,-3.14 -12.06,-6.87 C 68.24,11.4011 71.62,8.7610997 75.91,8.7610997 81,8.7110997 85,12.3111 88,15.681099 c -2.88,3.08 -6.77,7.02 -11.94,7.08 z M 75.95,10.7611 A 5,5 0 1 0 81,15.711099 5,5 0 0 0 75.95,10.7611 Z M 76,18.761099 a 3,3 0 1 1 3,-3 3,3 0 0 1 -3,3 z"
     id="path1-7"
     style="fill:#bbdd7a;fill-opacity:1" />
  <path
     d="m 120.13247,4.773769 -4.8,4.3300002 a 1.46,1.46 0 0 1 -1.64,0.12 l -1.47,-1.6000002 a 1.3,1.3 0 0 1 -0.19,-1.54 l 4.51,-4.8 a 8.1,8.1 0 0 0 -8.43,1.6 c -1.92,1.89 -3.23,5.03 -2.15,7.89 l -13.750004,12.81 c -1.43,1.42 -2.34,4.62 -0.92,6 l 0.65,0.69 c 1.43,1.42 4.66,-0.4 6.09,-1.81 l 8.800004,-9.31 v 0 l 2.91,-3.08 0.8,-0.81 v 0 0 c 3,1.3 6,-0.23 7.92,-2.13 1.92,-1.9 2.47,-5.82 1.67,-8.36 z m -4.06,7.9 a 5.45,5.45 0 0 1 -5.76,0.46 l -0.45,0.4 v 0 l -2.32,2.39 -1.35,1.44 v 0 l -10.140004,10.33 c -0.67,0.66 -2.17,1.51 -2.84,0.85 l -0.3,-0.3 c -0.67,-0.66 -0.24,-2.16 0.43,-2.82 l 15.000004,-14.28 v 0 -0.06 c -1.55,-1.7499993 -0.42,-5 1,-6.61 a 6.21,6.21 0 0 1 3.47,-1.84 l -2.72,3 a 2.76,2.76 0 0 0 0.45,3.3600002 l 1.65,1.8299998 c 2.74,2.58 6.36,-2 6.36,-2 0.09,0.8199997 -1.17,2.95 -2.48,3.85 z"
     id="path1-6"
     style="fill:#05d3d8;fill-opacity:1" />
</svg>
