<svg
   class="icon"
   viewBox="0 0 63.217964 30.299707"
   version="1.1"
   id="svg1"
   width="63.217964"
   height="30.299707"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs1" />
  <path
     d="m 51.217966,10.299707 h -4 v 16 h 4 z m 8,-7.0000003 h -4 V 26.299707 h 4 z m -24,25.0000003 V 0.2997067 h -2 V 30.299707 h 30 v -2 z m 8,-11 h -4 v 9 h 4 z"
     id="path1-5"
     style="fill:#06b4ea;fill-opacity:1" />
  <path
     d="m 12.966794,27.516 -1.067,-8.539 c -0.005,-0.036 -0.025,-0.065 -0.034,-0.099 -0.013,-0.05 -0.025,-0.098 -0.048,-0.144 -0.022,-0.044 -0.051,-0.081 -0.08,-0.119 -0.031,-0.04 -0.059,-0.078 -0.096,-0.11 -0.036,-0.031 -0.074,-0.053 -0.115,-0.077 -0.044,-0.027 -0.087,-0.052 -0.137,-0.07 -0.044,-0.015 -0.091,-0.02 -0.139,-0.028 -0.037,-0.005 -0.07,-0.022 -0.109,-0.022 H 9.6037941 v -4.362 c 0,-0.01 -0.005,-0.017 -0.006,-0.027 10e-4,-0.009 0.006,-0.017 0.006,-0.027 0,-2.435 1.9799999,-4.416 4.4149999,-4.416 2.435,0 4.416,1.981 4.416,4.416 0,0.01 0.005,0.018 0.006,0.027 -0.001,0.01 -0.006,0.017 -0.006,0.027 v 4.362 h -1.538 c -0.035,0 -0.065,0.016 -0.098,0.02 -0.054,0.007 -0.105,0.013 -0.156,0.031 -0.044,0.016 -0.082,0.039 -0.122,0.063 -0.044,0.025 -0.086,0.05 -0.124,0.083 -0.037,0.032 -0.066,0.07 -0.095,0.109 -0.03,0.038 -0.059,0.075 -0.081,0.119 -0.024,0.046 -0.035,0.095 -0.049,0.146 -0.009,0.033 -0.028,0.062 -0.033,0.098 l -1.068,8.539 z m -1.062,-22.832 c 0,-1.166 0.949,-2.113 2.114,-2.113 1.166,0 2.114,0.947 2.114,2.113 0,1.166 -0.948,2.115 -2.114,2.115 -1.165,0 -2.114,-0.949 -2.114,-2.115 z m 8.057,15.151 v -5.889 c 0,-0.01 -0.005,-0.017 -0.005,-0.027 0,-0.009 0.005,-0.017 0.005,-0.027 0,-2.843 -2.009,-5.224 -4.681,-5.805 1.385,-0.515 2.379,-1.84 2.379,-3.403 0,-2.007 -1.633,-3.641 -3.641,-3.641 -2.007,0 -3.641,1.634 -3.641,3.641 0,1.563 0.994,2.888 2.379,3.403 -2.672,0.581 -4.6809999,2.962 -4.6809999,5.805 0,0.01 0.005,0.018 0.005,0.027 0,0.01 -0.005,0.017 -0.005,0.027 v 5.889 h 2.3919999 l 1.151,9.208 h 4.8 l 1.152,-9.208 z"
     id="path1"
     style="fill:#e577d0;fill-opacity:1" />
  <path
     d="m 20.538094,6.4111 c 0,-1.166 0.947,-2.114 2.112,-2.114 1.167,0 2.116,0.948 2.116,2.114 0,1.166 -0.949,2.114 -2.116,2.114 -1.165,0 -2.112,-0.948 -2.112,-2.114 m 7.48,9.553 c 0,-3.249 -1.61,-5.573 -4.118,-6.147 1.393,-0.512 2.393,-1.839 2.393,-3.406 0,-2.007 -1.634,-3.641 -3.643,-3.641 -2.007,0 -3.639,1.634 -3.639,3.641 0,1.802 1.318,3.292 3.039,3.58 -0.095,0.128 -0.162,0.277 -0.162,0.448 0,0.422 0.342,0.764 0.763,0.764 2.831,0 3.841,2.459 3.841,4.761 0,0.01 0.005,0.019 0.006,0.029 -10e-4,0.01 -0.006,0.019 -0.006,0.029 v 2.862 h -0.963 c -0.031,0 -0.057,0.014 -0.088,0.018 -0.05,0.006 -0.097,0.013 -0.145,0.029 -0.05,0.016 -0.094,0.038 -0.138,0.063 -0.04,0.022 -0.077,0.046 -0.112,0.076 -0.04,0.033 -0.073,0.069 -0.105,0.11 -0.03,0.036 -0.056,0.073 -0.079,0.116 -0.023,0.045 -0.039,0.091 -0.053,0.141 -0.009,0.03 -0.029,0.054 -0.034,0.085 l -1.045,6.267 h -2.143 l -0.482,-3.363 c -0.06,-0.417 -0.442,-0.707 -0.864,-0.649 -0.417,0.06 -0.707,0.447 -0.647,0.864 l 0.669,4.676 h 4.762 l 1.152,-6.906 h 1.841 v -4.389 c 0,-0.01 -0.004,-0.019 -0.005,-0.029 0.001,-0.01 0.005,-0.019 0.005,-0.029"
     id="path2"
     style="fill:#e577d0;fill-opacity:1" />
  <path
     d="m 3.3144941,5.9843 c 0.157,-0.817 0.839,-1.495 1.657,-1.648 0.715,-0.135 1.409,0.079 1.912,0.582 0.501,0.503 0.712,1.2 0.577,1.913 -0.155,0.818 -0.834,1.497 -1.651,1.653 -0.712,0.139 -1.414,-0.075 -1.919,-0.581 -0.504,-0.505 -0.714,-1.204 -0.576,-1.919 m 4.482,15.793 c -0.423,-0.058 -0.805,0.231 -0.864,0.648 l -0.481,3.364 h -2.144 l -1.045,-6.267 c -0.005,-0.031 -0.024,-0.055 -0.032,-0.084 -0.016,-0.051 -0.031,-0.099 -0.057,-0.145 -0.021,-0.039 -0.046,-0.074 -0.073,-0.109 -0.035,-0.044 -0.07,-0.083 -0.114,-0.118 -0.032,-0.027 -0.065,-0.048 -0.102,-0.069 -0.047,-0.028 -0.093,-0.05 -0.147,-0.067 -0.046,-0.015 -0.091,-0.022 -0.14,-0.029 -0.03,-0.003 -0.057,-0.018 -0.089,-0.018 h -0.963 v -2.861 c 0,-0.011 -0.004,-0.019 -0.005,-0.029 10e-4,-0.01 0.005,-0.019 0.005,-0.029 0,-2.302 1.01,-4.761 3.841,-4.761 0.421,0 0.764,-0.343 0.764,-0.764 0,-0.169 -0.066,-0.318 -0.159,-0.445 0.034,-0.005 0.069,-0.003 0.105,-0.01 1.44,-0.274 2.591,-1.427 2.865,-2.869 0.227,-1.198 -0.146,-2.423 -0.997,-3.277 -0.852,-0.852 -2.08,-1.228 -3.273,-1.004 -1.442,0.27 -2.6,1.42 -2.877,2.861 -0.231,1.202 0.141,2.431 0.996,3.287 0.379,0.38 0.835,0.655 1.327,0.835 -2.508,0.574 -4.11900004,2.898 -4.11900004,6.147 0,0.01 0.006,0.019 0.007,0.029 -10e-4,0.01 -0.007,0.018 -0.007,0.029 v 4.389 H 1.8614941 l 1.151,6.906 h 4.763 l 0.669,-4.676 c 0.059,-0.417 -0.231,-0.804 -0.648,-0.864"
     id="path3"
     style="fill:#e577d0;fill-opacity:1" />
</svg>
