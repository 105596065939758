import { Component, ElementRef, Input } from '@angular/core';
import { HostBinding, HostListener } from '@angular/core';
import { DswSoModule } from '../dsw-so.module';
import { Sponsorship, Sponsor, DswService } from '../dsw.service';
import { SessionsHereComponent } from '../sessions-here/sessions-here.component';

import * as moment from 'moment'; 

@Component({
  standalone: true,
  imports: [DswSoModule, SessionsHereComponent],
  selector: 'venue-signage',
  templateUrl: './venue-signage.component.html',
  styleUrls: ['./venue-signage.component.scss']
})
export class VenueSignageComponent {
  ready = false;
  title = 'dsw-signage';
  sponsorships: Sponsorship[];
  fontHeight1920 = 12;
  fontHeight1080 = 10;
  fontHeight = this.fontHeight1920;
  testMode = false;
  testMoment: moment.Moment;
  clockIntervalDelay = 5000;
  venueIds:string[] = [];
  @Input() venue: string;
  @Input() csrf: string;
  @HostBinding('class') get venuClass() {
    return "venu-background";
  }
  @HostBinding('style.font-size.px') get fontSize() {
    return this.fontHeight;
  }
  clockInterval: any;
  stopTest() {
    clearInterval(this.clockInterval);
  }
  
  hereSize() {
    if (this.venueIds.length > 1) {
      let fontSize = 1 - (.2 * this.venueIds.length);
      let width = .9 * 1/this.venueIds.length * 100;
      return {'font-size.em': fontSize, 'width.vw': width};
    }
    return undefined;
  }
  constructor(protected element: ElementRef, public dswService: DswService) {
    let venueIds = element.nativeElement.getAttribute("venue");
    this.csrf = element.nativeElement.getAttribute("csrf");
    const parameters = new URLSearchParams(window.location.search);
    console.log(venueIds);
    if (venueIds) {
      this.venueIds = venueIds.split(",");
    }
    let test = parameters.get("test");
    if (test) {
      this.testMode = true;
      this.testMoment = moment('2024-09-18 07:00');
      this.clockIntervalDelay = 1000;
    }
    
    this.dswService.getSponsors().subscribe((results) => {
      this.sponsorships = results;
      console.log("sponsors", results);
      });
    this.updateClock();
    this.clockInterval = setInterval(() => { this.updateClock() }, this.clockIntervalDelay);
  }
  
  resizeTimout;
  localDate: string;
  localTime: string;  
   @HostListener('window:resize', ['$event']) resize(event) {
    if (this.ready) {
      clearTimeout(this.resizeTimout);
      this.resizeTimout = setTimeout((event) => this.checkResize(event), 250);
    }
  }
  
  checkResize(event) {
    let htmlElement = document.getElementsByTagName('html')[0];
    this.dswService.containerWidthpx = htmlElement.offsetWidth;
    this.dswService.containerHeightPx = htmlElement.offsetHeight;
    let newFontHeight = this.fontHeight;
    if (this.dswService.containerHeightPx > this.dswService.containerWidthpx) {
      newFontHeight = this.fontHeight1080 * this.dswService.containerWidthpx / this.dswService.defaultHeightpx;
    }
    else {
      newFontHeight = this.fontHeight1920 * this.dswService.containerWidthpx / this.dswService.defaultWidthpx;
    }
    console.log("check resize", this.dswService.containerWidthpx, this.dswService.containerHeightPx, this.fontSize);
    if (newFontHeight != this.fontHeight) {
      setTimeout(() => {
          this.fontHeight = newFontHeight;
      }, 250);
    }
  }

  
  logoClass(sponsor: Sponsor) {
      return sponsor.level;
  }
  updateClock() {
    let now = moment();
    if (this.testMode) {
      now = this.testMoment;
    }
    this.dswService.nowChangeEmitter.next(now);
    this.localTime = now.format('hh:mm A');
    this.localDate = now.format('MMMM Do YYYY');
  
    if (this.testMode) {
      this.testMoment = this.testMoment.add(30, 'minutes');
      if (this.testMoment.hour() > 19) {
        this.testMoment = this.testMoment.add(1, 'days').hour(7);
        console.log("New Day", this.testMoment);
      }
    }
    // console.log("Update Clock ", this, this.dateTime);
  }
    
  ngAfterViewInit(): void {
    this.checkResize(undefined);
  }

}
