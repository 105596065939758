<mat-card>
  <mat-card-header style="width: 100%;">
    <mat-card-subtitle class="track">
      <div class="track-date">
        <div class="track-name">{{venueSession.track.name}}&nbsp;Track Event</div>
        <div class="time">
		      <span>{{sessionDate}}</span>
		      &nbsp;&nbsp;
		      <span>{{sessionTime}}</span>
        </div>
        <div class="expertise">
		      <div class="level" *ngIf="venueSession.expertise_level">
		        <track-icon [track]="venueSession.expertise_level"></track-icon>
		      {{venueSession.expertise_level}}
		      </div>
        </div>
      </div>
    </mat-card-subtitle>
    <mat-card-title>
      <track-icon [track]="venueSession.track"></track-icon>
      <div class="title">
        {{venueSession.title}}
      </div>
     </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="description">{{venueSession.description}}</p>
    <target-audiemce-chips *ngIf="venueSession.target_audiance" [targetAudience]="venueSession.target_audiance"></target-audiemce-chips>
    <mat-list *ngIf="venueSession.presenters">
      <mat-list-item *ngFor="let presenter of venueSession.presenters">
        <div class="avatar-wrap" matListItemIcon>
          <img *ngIf="avatarUrl(presenter)" class="avatar" [src]="avatarUrl(presenter)">
        </div>
        <div matListItemTitle>{{presenter.name}}</div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>  
  
