<ng-container *ngIf="venue && sessions">
  <div class="venue">
      <div class="name">{{venue.name}}</div>
      <div class="address">{{venue.address}}</div>
  </div>
  <div class="activeSession" *ngIf="activeSession">
    <div class="cardDescr">HAPPENING NOW</div>
    <session-card [venueSession]="activeSession"></session-card>
  </div>
  <div class="commingSession" *ngIf="commingSession">
    <div class="cardDescr">COMING UP</div>
    <session-card [venueSession]="commingSession"></session-card>
  </div>
  <div class="thats-all" *ngIf="thatsAll">
    <h1>!!! THAT'S ALL FOLKS, SEE YOU NEXT YEAR !!!!</h1>
    <dsw-primary></dsw-primary>
    <h3>For the community, by the community.</h3>
    <h3>Thank You for participating!!</h3>
  </div>
</ng-container>