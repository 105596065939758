import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DswService } from '../dsw.service';

export interface Venue {
  name: string;
  venue_id: string;
}
@Component({
  selector: 'pick-venue',
  templateUrl: './pick-venue.component.html',
  styleUrls: ['./pick-venue.component.scss']
})
export class PickVenueComponent {
  venues: Venue[] = [
    {
      venue_id: "49232",
      name: "Create Venue - Commons on Champa"
    }, 
      {
        venue_id: "78",
        name: "Create Venue - Code Talent"
      }, {
      venue_id: "49231",
      name: "Grow Venue - Jake Jabs Center"
    }, {
      venue_id: "49233",
      name: "Operate Venue - McNichols Floor 3"
    }, {
      venue_id: "51765",
      name: "Ignite Venue - McNichols Floor 2"
    }];
  selectedVenues: string[] = [];
  get noVenueSelected(): boolean {
    return this.selectedVenues.length == 0;
  }

  displayVenues() {
    let venueParam = "";
    let first = true;
    for (let venue_id of this.selectedVenues) {
      if (!first) {
        venueParam += ",";
      }
      first = false;
      venueParam += venue_id;
    }
    if (window.location.search && window.location.search.includes("test=true")) {
      window.location.search = window.location.search + "&venue=" + venueParam;
    }
    else {
      window.location.search = "venue=" + venueParam;
    }
  }
  constructor(public dswService: DswService) {
  }


  selectVenue(selectedEvent: MatSelectChange) {
    console.log("Venue selected ", selectedEvent);
    this.selectedVenues = selectedEvent.value;


    // this.venueComponent._venueId = selectedEvent.value;    
  }

}
