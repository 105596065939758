import { Component, Input, ElementRef } from '@angular/core';

import { VenueSession, Presenter } from '../dsw.service';
import { DswSoModule } from '../dsw-so.module';
import { TargetAudiemceChipsComponent } from '../target-audiemce-chips/target-audiemce-chips.component';

@Component({
  standalone: true,
  imports: [DswSoModule, TargetAudiemceChipsComponent],
  selector: 'session-card',
  templateUrl: './session-card.component.html',
  styleUrls: ['./session-card.component.scss'],
  
})
export class SessionCardComponent {
  @Input() venueSession: VenueSession;
  @Input() isActive: boolean;
//  @HostBinding('class') get cssClass() {
//    if (this.venueSession && this.venueSession.track) {
//      return this.venueSession.track.name.toLowerCase() + '-session';
//    }
//    return 'growth-session';
//  }
  
  constructor(public parentElement: ElementRef) { }
  
  avatarUrl(presenter: Presenter): string {
    if (presenter.avatar && presenter.avatar.thumb && presenter.avatar.thumb.url) {
      if (presenter.avatar.thumb.url.startsWith("http")) {
        return presenter.avatar.thumb.url;
      }
      return "https://dbiqieltvc37u.cloudfront.net" + presenter.avatar.thumb.url; 
    }
  }
  
  get sessionDate(): string {
    if (this.venueSession) {
      return this.venueSession.start_time.format("dddd, MMM DD");
    }
    return "";
  }
  
  get sessionTime(): string {
    if (this.venueSession) {
      return this.venueSession.start_time.format("hh:mm A") + " - " + this.venueSession.end_time.format("hh:mm A");
    }
    return "";
  }

  ngOnInit() {
    let headerText = this.parentElement.nativeElement.getElementsByClassName("mat-mdc-card-header-text");
    console.log("headerText", headerText);
    if (headerText) {
      headerText[0].style.setProperty('width', '100%');
    }
  }
}
