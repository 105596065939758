import { Component, Type, Input } from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';

import { DesignerComponent } from './designer/designer.component';
import { SpotlightComponent } from './spotlight/spotlight.component';
import { PeopleComponent } from './people/people.component';
import { FounderComponent } from './founder/founder.component';
import { DeveloperComponent } from './developer/developer.component';
import { GrowthComponent } from './growth/growth.component';
import { MakerComponent } from './maker/maker.component';
import { ProductComponent } from './product/product.component';
import { MartiniComponent } from './martini/martini.component';
import { StarComponent } from './star/star.component';
import { IntermediateComponent } from './intermediate/intermediate.component';
import { BeginnerComponent } from './beginner/beginner.component';
import { AdvancedComponent } from './advanced/advanced.component';
import { ExpertComponent } from './expert/expert.component';

import { Track } from '../dsw.service';

@Component({
  selector: 'track-icon',
  template: '<ng-template [cdkPortalOutlet]="componentPortal"></ng-template>',
  styleUrls: ['./track-icon.component.scss']
})
export class TrackIconComponent  {
  _track: Track | string;
  @Input() set track(track: Track | string) {
    let name = '';
    if (this._track != track) {
      this._track = track;
      if (this._track instanceof Object) {
        name = (track as Track).name.toLowerCase();
      }
      else {
        name = (track as string).toLowerCase();
      }
      let type:Type<any> = this.componentMap.get(name);
      if (type == null && track instanceof Object) {
        type = this.componentMap.get(track.icon);
      }
      if (type) {
        this.componentPortal = new ComponentPortal(type);
      }
    }    
  }
  get track(): Track | string {
    return this._track;
  }
  
  componentMap: Map<string, Type<any>>;
  componentPortal: ComponentPortal<any>;
  constructor() { 
    this.componentMap = new Map<string, Type<any>>;
    this.componentMap.set("developer", DeveloperComponent);
    this.componentMap.set("designer", DesignerComponent);
    this.componentMap.set("martini", MartiniComponent);
    this.componentMap.set("ignite", SpotlightComponent);
    this.componentMap.set("star", StarComponent);
    this.componentMap.set("people", PeopleComponent);
    this.componentMap.set("founder", FounderComponent);
    this.componentMap.set("grow", GrowthComponent);
    this.componentMap.set("maker", MakerComponent);
    this.componentMap.set("product", ProductComponent);
    this.componentMap.set("introductory and overview", BeginnerComponent);
    this.componentMap.set("intermediate", IntermediateComponent);
    this.componentMap.set("advanced", AdvancedComponent);
    this.componentMap.set("expert", ExpertComponent);
    
  }


}
