import { Component, ElementRef, EventEmitter, Output } from '@angular/core';

import { MatButtonToggleModule, MatButtonToggleChange } from '@angular/material/button-toggle';

import { DswSoModule } from '../dsw-so.module';
import { IconsModule } from 'angular/icons/icons.module';

@Component({
  standalone: true,
  imports: [
    DswSoModule,
    MatButtonToggleModule,
    IconsModule
  ],
  selector: 'track-buttons',
  templateUrl: './track-buttons.component.html',
  styleUrls: ['./track-buttons.component.scss']
})
export class TrackButtonsComponent {
  toggleValue = ["create", "grow", "ignite", "operate"];
  mine: string;
  constructor() {
    let element = document.getElementById('filter');
    console.log("Find filter?", element);
    if (element && element['value']) {
      if (element['value'] == 'mine') {
        this.toggleValue = [];
        this.mine = element['value'];
      }
      else {
        this.toggleValue = element['value'].split(',').map(value => value.trim());
        this.mine = undefined;
        console.log("parse", element['value'], this.toggleValue);
      }
    }
  }

  toggleMine(toggleChange: MatButtonToggleChange) {
    console.log("Button toggled", toggleChange);
    this.setValue('mine');
  }

  setValue(value: string) {
    let element = document.getElementById('filter');
    element['value'] = value;
    const event = new Event('change', { bubbles: true });
    element.dispatchEvent(event);

  }
  toggleChanged(toggleChange: MatButtonToggleChange) {
    console.log("Button toggled", toggleChange);
    this.setValue(toggleChange.value);
  }

  //  ngOnInit() {
  //    let element = document.getElementById('filter');
  //    console.log("Find filter in init?", element);
  //    if (element && element['value']) {
  //      this.toggleValue = element['value'].split(',').map(value => value.trim());
  //      console.log("parse", element['value'], this.toggleValue);
  //    }
  //    
  //  }
}
