<svg
   version="1.1"
   id="svg48"
   width="669"
   height="501"
   viewBox="0 0 669.00005 501.75"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs52" />
  <g
     id="g56"
     transform="matrix(1.3333333,0,0,-1.3333333,0,800)">
    <g
       id="g58"
       transform="matrix(0.1,0,0,0.1,-147.80916,110.4187)">
      <path
         d="m 2037.33,2029.32 c 0,170.76 -107.69,301.26 -287.14,301.26 h -133.78 v -602.53 h 133.78 c 179.45,0 287.14,131.61 287.14,301.27 z m 91.34,0 c 0,-212.08 -158.79,-380.66 -378.48,-380.66 h -226.22 v 761.32 h 226.22 c 219.69,0 378.48,-168.57 378.48,-380.66"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path60" />
      <path
         d="m 2942.24,1730.23 v -81.57 h -487.25 v 761.32 h 474.2 v -81.56 h -379.58 v -256.69 h 272.99 v -81.56 h -272.99 v -259.94 h 392.63"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path62" />
      <path
         d="m 3886.32,1642.13 h -3.26 l -511.18,563.38 v -556.85 h -92.44 v 767.85 h 3.26 l 511.18,-565.55 v 559.02 h 92.44 v -767.85"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path64" />
      <path
         d="m 4542.17,1648.66 h -16.3 l -334.99,761.32 h 33.72 l 308.88,-704.76 309.96,704.76 h 33.72 l -334.99,-761.32"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path66" />
      <path
         d="m 5639.62,1678.03 v -29.37 h -457.88 v 761.32 h 444.83 v -29.36 h -412.21 v -333.9 h 305.62 v -30.45 h -305.62 v -338.24 h 425.26"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path68" />
      <path
         d="m 6219.34,1976.03 c 140.3,0 218.61,78.3 218.61,203.38 0,126.16 -78.31,202.3 -218.61,202.3 h -210.99 v -405.68 z m 252.32,-327.37 -227.3,300.18 c -9.79,-1.09 -18.5,-1.09 -28.29,-1.09 h -207.72 v -299.09 h -31.55 v 761.32 h 239.27 c 155.53,0 254.51,-103.31 254.51,-230.57 0,-109.85 -73.96,-203.38 -194.69,-226.22 l 231.67,-304.53 h -35.9"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path70" />
      <path
         d="m 1632.29,1304.45 -15.67,-5.81 c -7.54,16.84 -25.54,31.63 -51.65,31.63 -27.57,0 -45.84,-14.79 -45.84,-34.82 0,-20.6 13.92,-28.44 34.81,-35.68 l 24.37,-8.42 c 31.92,-11.03 56.58,-25.83 56.58,-58.04 0,-33.65 -34.52,-54.26 -69.92,-54.26 -33.66,0 -62.95,19.74 -72.53,47.59 l 15.67,6.67 c 8.12,-22.05 29.59,-38.29 56.86,-38.29 26.99,0 51.65,13.92 51.65,38.29 0,24.67 -19.74,33.66 -41.78,41.21 l -25.24,8.7 c -28.15,9.87 -48.75,23.22 -48.75,52.23 0,28.43 27.56,50.78 64.12,50.78 33.95,0 57.74,-20.03 67.32,-41.78"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path72" />
      <path
         d="m 1827.2,1327.95 h -60.64 v -186.86 h -18.57 v 186.86 h -60.93 v 16.25 h 79.5 60.64 v -16.25"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path74" />
      <path
         d="m 1986.43,1215.95 -41.78,94.59 -41.49,-94.59 z m 6.68,-15.38 h -96.63 l -26.11,-59.48 h -20.02 l 93.44,205.43 h 1.73 l 93.44,-205.43 h -19.74 l -26.11,59.48"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path76" />
      <path
         d="m 2116.6,1233.35 h 47.89 c 32.78,0 51.35,17.71 51.35,47.59 0,29.89 -18.57,47.01 -51.35,47.01 h -47.89 z m 108.23,-92.26 -55.42,76.6 h -52.81 v -76.6 h -18.29 v 203.11 h 66.18 c 43.23,0 69.62,-27.57 69.62,-63.26 0,-29.02 -17.12,-52.52 -46.72,-60.35 l 58.05,-79.5 h -20.61"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path78" />
      <path
         d="m 2431.92,1327.95 h -60.63 v -186.86 h -18.57 v 186.86 h -60.93 v 16.25 h 79.5 60.63 v -16.25"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path80" />
      <path
         d="m 2652.07,1217.69 c 0,-49.04 -32.2,-78.64 -76.88,-78.64 -44.7,0 -76.62,29.6 -76.62,78.64 v 126.51 h 18.01 v -126.51 c 0,-40.62 23.2,-62.67 58.61,-62.67 35.39,0 58.9,22.05 58.9,62.67 v 126.51 h 17.98 v -126.51"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path82" />
      <path
         d="m 2837.68,1280.94 c 0,29.89 -18.57,47.01 -51.35,47.01 h -47.89 v -94.6 h 47.89 c 32.78,0 51.35,17.71 51.35,47.59 z m 18.27,0 c 0,-35.97 -26.39,-63.25 -69.62,-63.25 h -47.89 v -76.6 h -18.28 v 203.11 h 66.17 c 43.23,0 69.62,-27.57 69.62,-63.26"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path84" />
      <path
         d="m 3192.15,1141.09 h -4.08 l -64.7,165.09 -65.29,-165.09 h -4.05 l -69.06,203.11 h 19.14 l 53.39,-160.17 63.55,160.17 h 4.36 l 63.25,-160.17 53.39,160.17 h 19.15 l -69.05,-203.11"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path86" />
      <path
         d="m 3450.25,1157.62 v -16.53 h -126.8 v 203.11 h 123.33 v -16.55 h -104.46 v -76.3 h 76.01 v -16.25 h -76.01 v -77.48 h 107.93"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path88" />
      <path
         d="m 3645.16,1157.62 v -16.53 h -126.81 v 203.11 h 123.34 v -16.55 h -104.47 v -76.3 h 76.02 v -16.25 h -76.02 v -77.48 h 107.94"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path90" />
      <path
         d="m 3786.39,1245.83 98.66,-104.74 h -23.23 l -86.74,92.55 -38.89,-40.33 v -52.22 h -18.57 v 203.11 h 18.57 v -126.51 l 119.54,126.51 h 23.23 l -92.57,-98.37"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path92" />
      <path
         d="m 1507.88,2814.37 1391.1,1074.68 165.81,971.9 z"
         style="fill:#79d2d5;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path94" />
      <path
         d="M 4564.24,4552.24 5721.6,2814.37 h 785.96 z"
         style="fill:#79d2d5;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path96" />
      <path
         d="m 3558.84,3389.68 v 108.12 c 0,0 -7.89,116.78 70.35,204.2 l -306.62,78.54 13.52,-217.35 h 113.29 v -230.96 l 109.46,57.45"
         style="fill:#a2e0e1;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path98" />
      <path
         d="m 2605.83,3038.84 v 268 h 374.64 v -220.69 l 148.66,78.02 v 399.02 h 143.51 l 14.08,226.54 -387.74,99.32 -1391.1,-1074.68 h 954.71 l 143.24,75.17 v 149.3"
         style="fill:#a2e0e1;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path100" />
      <path
         d="m 3290.77,3854.74 h 27.19 l 4.61,-74.2 306.62,-78.54 c 44.32,51.52 116.4,92.55 233.8,92.55 v -111.31 c 0,0 83.64,-16.88 138.59,-72.87 l 1121.1,-73.59 -2057.89,1324.17 -165.81,-971.9 387.74,-99.32 4.05,65.01"
         style="fill:#c9edec;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path102" />
      <path
         d="m 3129.13,3365.1 -148.66,-122.83 v -203.43 h 148.66 v 326.26"
         style="fill:#b9e8e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path104" />
      <path
         d="m 3449.38,3563.19 v -748.82 h 109.46 v 683.43 c 0,0 -11.66,296.75 304.15,296.75 v -111.31 c 0,0 105.68,-21.16 157.5,-95.34 16.79,-24.13 27.94,-53.77 27.94,-90.1 v -44.22 -292.84 l 111.04,299.55 v 27.82 h 10.31 l 393.7,1062.12 -1194.6,-987.04 h 80.5"
         style="fill:#b9e8e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path106" />
      <path
         d="m 2605.83,2932.73 -143.24,-118.36 h 143.24 z"
         style="fill:#b9e8e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path108" />
      <path
         d="m 4561.63,3488.11 v -449.27 l 723.62,77.66 -175.31,-302.13 H 5721.6 L 4564.24,4552.24 4164.06,3488.11 h 397.57"
         style="fill:#a1e0e1;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path110" />
      <path
         d="m 4159.47,2964.61 v 511.28 l -111.04,-295.27 v -216.01 h 111.04"
         style="fill:#a1e0e1;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path112" />
    </g>
  </g>
</svg>
