import { Component } from '@angular/core';

@Component({
  selector: 'dsw-primary',
  templateUrl: './dsw-prmary.component.html',
  styleUrls: ['./dsw-prmary.component.scss']
})
export class DswPrmaryComponent {

}
