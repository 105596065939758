<mat-button-toggle-group name="tracks" aria-label="Tracks" appearance="legacy" (change)="toggleMine($event)" [value]="mine">
  <mat-button-toggle value="mine">
    <div class="track-button">
      <span class="community">My Schedule</span>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>
<mat-button-toggle-group name="tracks" aria-label="Tracks" appearance="legacy" multiple (change)="toggleChanged($event)" [value]="toggleValue">
  <mat-button-toggle value="create">
    <div class="track-button">
      <track-icon track="developer"></track-icon>
      Create
     </div>
    </mat-button-toggle>
  <mat-button-toggle value="grow">
    <div class="track-button">
      <track-icon track="growth"></track-icon>
      Grow
    </div>
  </mat-button-toggle>
  <mat-button-toggle value="ignite">
    <div class="track-button">
      <track-icon track="spotlight"></track-icon>
      Ignite
    </div>
  </mat-button-toggle>
  <mat-button-toggle value="operate">
    <div class="track-button">
      <track-icon track="people"></track-icon>
      Operate
    </div>
   </mat-button-toggle>
  <mat-button-toggle value="community">
    <div class="track-button">
      <span class="community">Community Events</span>
    </div>
   </mat-button-toggle>
</mat-button-toggle-group>